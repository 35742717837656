.app {
  display: flex;
  min-height: 100vh;
}
.sidebar {
      flex: 0 0 240px;
      background-color: lightblue;
  }

.content {
      flex: 1;
      padding: 40px;
  }
